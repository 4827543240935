.cardMenu
{
    cursor: pointer !important;
    color: black !important;
    text-decoration: none !important;
  
}


.cardMenu:hover
{
    cursor: pointer !important;
    background-color: #466646;
    color: white;
}