.tablaAgregado:hover {
  border-color: blue;
  background-color: chocolate;
}

.tablaSiCoincide {
  border-color: blue;
  background-color: darkolivegreen;
  color: white !important;
  font-weight:800;
}
