.menuContainer {
  background-color: #669966;
  background-image: linear-gradient(180deg, #466646 0%, #243324 100%);
  min-height: calc(100vh - 78px);
  max-height: calc(100vh - 78px);
  overflow: auto;
  color: white;
  text-align: left;
  cursor: pointer !important;
  font-size: 13px;
  z-index: 100;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.menuContainerDev {
  background: #8e2de2; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #4a00e0,
    #8e2de2
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #4a00e0,
    #8e2de2
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  min-height: calc(100vh - 92px);
  max-height: calc(100vh - 92px);
  overflow: auto;
  color: white;
  text-align: left;
  cursor: pointer !important;
  font-size: 13px;
  z-index: 100;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.contenidoSitio {
  margin-top: 120px;
  padding-left: 20px;
  padding-right: 20px;
  position: absolute;
  right: 0;
}

.menuContainer span {
  color: white !important;
  padding-left: 15px;
  padding-right: 10px;
  text-decoration: none;
}

.menuContainerDev span {
  color: white !important;
  padding-left: 15px;
  padding-right: 10px;
  text-decoration: none;
}

.buttonMenu {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #426042;
  display: block;
  text-decoration: none;
}

.sideBarAbierto {
  width: 230px;
  position: fixed;
  top: 78px;
  left: 0px;
  bottom: 0px !important;
}

.sideBarCerrado {
  width: 50px;
  position: fixed;
  top: 78px;
  left: 0px;
  bottom: 0px !important;
}
