@media print {
  @page {
    size: 5.5in 8.5in;
    margin: 0.5in;
  }

  .recibo {
    height: 40mm;
  }
}
