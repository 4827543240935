.cardPago {
  cursor: pointer;
  box-shadow: 0 4px 4px 1px rgba(0, 0, 0, 0.2), 0 4px 4px 0 rgba(0, 0, 0, 0.02);
  padding-top: 32vh;
  padding-bottom: 32vh;
}

.cardPago:hover {
  cursor: pointer;
  background-color: rgba(0,0,0,0.7);
  color: white;
}
