.fondoMenu {
  background-color: #f7f7f7;
  padding: 5px;
  font-size: 14px;
  padding-top: 20px;
  padding-bottom: 20px;
}
.hallNumber {
  background-color: #313e49;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.hallString {
  color: #313e49;
  font-weight: bolder;
  margin-left: 5px;
}
