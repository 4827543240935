@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@page {
  size: auto;
  /* auto is the initial value */

  /* this affects the margin in the printer settings */
  margin: 5mm 5mm 5mm 5mm;
}

body {
  font-family: "Roboto", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto", sans-serif;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cardLogin {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.16), 0 1px 1px 0 rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  background-color: #f4fcfe;
}

.list-group-item {
  cursor: pointer !important;
}

.classSelected {
  padding-top: 10px;
  padding-bottom: 10px;

  display: block;
  background-color: #252525;
}

.crudDivComponent {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  background-color: white;
  z-index: 500 !important;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  padding: 20px;
}

.print-source {
  display: none;
}

body>.print-source {
  display: block;
}

@media print {
  .print-source {
    display: block;
  }
}

@media all {
  .page-break {
    display: none;
  }
}

@media print {

  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
    font-size: 9px;
    margin: 0px !important;
    padding: 0px !important;
  }
}

@media print {
  .page-break {
    margin-top: 6rem;
    display: block;
    page-break-before: auto;
  }
}

@page {
  size: auto;
  margin: 10mm;
}

table {
  page-break-inside: auto !important;
}

tr {
  page-break-inside: avoid !important;
  page-break-after: auto !important;
}

@media print {
  .margenImpresionTop {
    margin-top: 0 !important;
    box-shadow: none;
    position: absolute;
    right: 0;
    left: 0;
    top: 10px !important;
    bottom: 10px !important;
    padding: 0 !important;
    left: 0 !important;
  }
}

.textoBotonXls {
  font-size: 12px !important;
}

:root {
  --width-menu-abierto: 230px;
  --width-menu-cerrado: 50px;
}


/* file upload button */
input[type="file"]::file-selector-button {
  border-radius: 4px;
  padding: 0 16px;
  height: 40px;
  cursor: pointer;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.16);
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
  margin-right: 16px;
  transition: background-color 200ms;
}

/* file upload button hover state */
input[type="file"]::file-selector-button:hover {
  background-color: #f3f4f6;
}

/* file upload button active state */
input[type="file"]::file-selector-button:active {
  background-color: #e5e7eb;
}

/* Define la clase para ocultar en la pantalla pero mostrar en la impresión */
.hide-on-screen {
  display: none;
}

@media print {
  .hide-on-screen {
    display: flex;
  }
}

.modoSoloLectura {
  pointer-events: none !important;
  opacity: 0.6;
  /* Opcional: para dar una indicación visual de que está deshabilitado */
}

.imgCancelado {
  position: absolute;
  top: 60%;
  left: 40%;
  transform: translate(-50%, -50%);
  /* Centrado exacto */
  width: 250px;

  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 18px;
  text-align: center;
  opacity: 0.4;
  border-radius: 10px;
  rotate: 45deg;
}