.cardPago {
  cursor: pointer;
  box-shadow: 0 4px 4px 1px rgba(0, 0, 0, 0.2), 0 4px 4px 0 rgba(0, 0, 0, 0.02);
}

.cardPago:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
}

.contenedorUltimosPagos {
  max-height: calc(100vh - 200px);
  overflow: auto;
}
