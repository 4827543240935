.modalAgregarPagoContainer {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: 0 8px 32px 0 rgb(31 38 135 / 37%);
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 5000;
    overflow: hidden;
    backdrop-filter: blur(10px);
    font-family: "Roboto", sans-serif;
  }
  