@import "../../node_modules/bootstrap/scss/functions";

$primary: #336699;
$secondary: #6c757d;
$success: #198754;
$danger: #dc3545;
$warning: #ffc107;
$info: #0dcaf0;

$body-bg: #f7f7f7;

$btn-border-radius: 20px;
$btn-border-radius-sm: 20px;
$btn-border-radius-lg: 20px;
$font-size-root: 0.9rem;

@import "../../node_modules/bootstrap/scss/bootstrap.scss";
