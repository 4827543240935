.fondoMenu {
  background-color: #243324;
  background-image: linear-gradient(180deg, #243324 0%, #466646 100%);

  padding: 5px;
  font-size: 14px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: white;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 400;
}

.fondoMenuDevelopment {

  background: #8E2DE2;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #4A00E0, #8E2DE2);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #4A00E0, #8E2DE2);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  padding: 5px;
  font-size: 14px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: white;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 400;
}

.disableSelect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.disableSelect:hover {
  color: #ffffff;
}